import Icon, { CheckCircleFilled, DownOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu, message, Modal, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FilterValue, SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { Component, Key } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../../../apis/DivisionApi';
import eventApi from '../../../../apis/EventApi';
import eventPlayerApi from '../../../../apis/EventPlayerApi';
import userApi from '../../../../apis/UserApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Page } from '../../../../model/Elements';
import { Division, Event, EventPlayer, EventStaff, UserOrganizationMember } from '../../../../model/Entities';
import { participatedStatus, UserStatusType } from '../../../../model/Types';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as eventSettingsSvg } from '../../../../resources/images/ico-eventSettings.svg';
import { ReactComponent as eventLeaderboardSvg } from '../../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as positionsSvg } from '../../../../resources/images/ico-positions.svg';
import { ReactComponent as eventSummarySvg } from '../../../../resources/images/ico-summary.svg';
import HeadMetadata from '../../../../services/HeadMetadata';
import notificationService from '../../../../services/NotificationService';
import rolesService from '../../../../services/RolesService';
import stringService from '../../../../services/StringService';
import tableService from '../../../../services/TableService';
import AddExistingPlayerModal from './AddExistingPlayerModal/AddExistingPlayerModal';
import AddGroupsModal from './AddGroupsModal/AddGroupsModal';
import styles from './EventPlayersPage.module.scss';

class EventPlayersPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = { selectedRowKeys: [], divisions: [], eventStaff: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const responses = await Promise.all([
                eventApi.get(+this.props.match.params.id),
                eventPlayerApi.list(this.pageSize, 1, +this.props.match.params.id),
                eventApi.listAllStaff(+this.props.match.params.id),
            ]);
            const event = responses[0];
            const playersPage = responses[1];
            const eventStaff = responses[2];
            const divisions: Division[] = await divisionApi.list(event?.sportId!);

            this.setState({ event, playersPage, divisions, eventStaff });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<EventPlayer> | SorterResult<EventPlayer>[],
    ) => {
        const { searchText, participated } = this.state;
        try {
            this.setState({ loading: 'loadingPlayers' });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;

            let sortField, sortOrder;
            if (Array.isArray(sorter)) {
            } else {
                sortField = sorter.field as string;
                sortOrder = sorter.order as string;
            }

            const playersPage = await eventPlayerApi.list(
                pageSize,
                page,
                +this.props.match.params.id,
                searchText,
                participated,
                sortField,
                sortOrder,
            );

            this.setState({ playersPage, sortField, sortOrder });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    updatePlayers = async () => {
        const { searchText, participated, sortField, sortOrder } = this.state;
        try {
            this.setState({ loading: 'loadingPlayers' });

            const playersPage = await eventPlayerApi.list(
                this.pageSize,
                1,
                +this.props.match.params.id,
                searchText,
                participated,
                sortField,
                sortOrder,
            );

            this.showGroupsModal(false);
            this.showExistingUserModal(false);

            this.setState({ playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    listStaff = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<EventStaff> | SorterResult<EventStaff>[],
    ) => {
        try {
            this.setState({ loading: 'loadingStaff' });

            let sortField, sortOrder;
            if (Array.isArray(sorter)) {
            } else {
                sortField = sorter.field as string;
                sortOrder = sorter.order as string;
            }

            const eventStaff = await eventApi.listAllStaff(+this.props.match.params.id, sortField, sortOrder);

            this.setState({ eventStaff });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    sendEvalEmails = async (eventId: number) => {
        const { selectedRowKeys, searchText, participated, sortField, sortOrder } = this.state;
        try {
            this.setState({ loading: 'sendingEmails' });

            await eventApi.sendEmails(eventId, selectedRowKeys as string[]);
            message.success(this.props.intl.formatMessage({ id: 'status.emailsSent' }));
            this.showModalSendEvalEmails(false);

            const playersPage = await eventPlayerApi.list(
                this.pageSize,
                1,
                +this.props.match.params.id,
                searchText,
                participated,
                sortField,
                sortOrder,
            );

            this.setState({ playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    sendInvites = async () => {
        const { playersPage, selectedRowKeys, searchText, participated, sortField, sortOrder } = this.state;
        try {
            this.setState({ loading: 'sendingInvites' });

            const players = playersPage && playersPage.items ? playersPage.items : [];

            const notInvitedPlayerIds = players
                .filter((u) => selectedRowKeys.includes(u.id!))
                .filter((p) => p.userStatus === 'None')
                .flatMap((u) => u.id!);
            const invitedPlayerIds = players
                .filter((u) => selectedRowKeys.includes(u.id!))
                .filter((p) => p.userStatus !== 'None')
                .flatMap((u) => u.id!);
            notInvitedPlayerIds.length > 0 && (await userApi.sendInvites(notInvitedPlayerIds));
            invitedPlayerIds.length > 0 && (await userApi.sendReInvites(invitedPlayerIds));
            message.success(this.props.intl.formatMessage({ id: 'status.invitesSent' }));
            this.showModalSendInvites(false);

            const playersPageAux = await eventPlayerApi.list(
                this.pageSize,
                1,
                +this.props.match.params.id,
                searchText,
                participated,
                sortField,
                sortOrder,
            );

            this.setState({ playersPage: playersPageAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText: string) => {
        const { participated, sortField, sortOrder } = this.state;
        try {
            this.setState({ loading: 'searching' });
            const playersPage = await eventPlayerApi.list(
                this.pageSize,
                1,
                +this.props.match.params.id,
                searchText,
                participated,
                sortField,
                sortOrder,
            );

            this.setState({ playersPage, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByParticipatedStatus = async (value: string) => {
        const { searchText, sortField, sortOrder } = this.state;
        try {
            this.setState({ loading: 'filtering' });

            let participated;
            if (value === 'participated') {
                participated = true;
            } else if (value === 'noShow') {
                participated = false;
            }

            const playersPage = await eventPlayerApi.list(
                this.pageSize,
                1,
                +this.props.match.params.id,
                searchText,
                participated,
                sortField,
                sortOrder,
            );

            this.setState({ playersPage, participated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    getDivision = (player: EventPlayer) => {
        const { event, divisions } = this.state;
        const userOrgMember: UserOrganizationMember | undefined =
            player.userOrganizationMembers && player.userOrganizationMembers.length > 0
                ? player.userOrganizationMembers.find((uom) => uom.sportId === event?.sportId)
                : undefined;
        const divisionId =
            userOrgMember && userOrgMember.teams && userOrgMember.teams.length > 0
                ? userOrgMember.teams[0].division?.id
                : undefined;
        return divisions.find((d) => d.id === divisionId)?.name;
    };

    getTeam = (player: EventPlayer) => {
        const { event } = this.state;
        const userOrgMember: UserOrganizationMember | undefined =
            player.userOrganizationMembers && player.userOrganizationMembers.length > 0
                ? player.userOrganizationMembers.find((uom) => uom.sportId === event?.sportId)
                : undefined;
        const team =
            userOrgMember && userOrgMember.teams && userOrgMember.teams.length > 0
                ? userOrgMember.teams[0].name
                : undefined;
        return team;
    };

    getAffiliate = (player: EventPlayer) => {
        const { event } = this.state;
        const userOrgMember: UserOrganizationMember | undefined =
            player.userOrganizationMembers && player.userOrganizationMembers.length > 0
                ? player.userOrganizationMembers.find((uom) => uom.sportId === event?.sportId)
                : undefined;

        return userOrgMember?.memberType;
    };

    select = (selectedRowKeys: Key[]) => {
        this.setState({ selectedRowKeys });
    };

    showModalSendInvites = (modalSendInvitesVisible: boolean) => {
        this.setState({ modalSendInvitesVisible });
    };

    showModalSendEvalEmails = (modalSendEvalEmailsVisible: boolean) => {
        this.setState({ modalSendEvalEmailsVisible });
    };

    showGroupsModal = (groupsModalVisible: boolean) => {
        this.setState({ groupsModalVisible });
    };

    showExistingUserModal = (existingUserModalVisible: boolean) => {
        this.setState({ existingUserModalVisible });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { event } = this.state;
        if (desktop) {
            return (
                <>
                    <div className={styles.header}>
                        <div className={styles.col}>
                            <h1>
                                <FormattedMessage id="events.event.athletes.title" />
                            </h1>
                            {event && (
                                <p>
                                    {event?.name} - <FormattedDate value={event?.startDate} month="short" />{' '}
                                    <FormattedDate value={event?.startDate} day="2-digit" />,{' '}
                                    <FormattedDate value={event?.startDate} year="numeric" />,{' '}
                                    <FormattedDate value={event?.startDate} hour="2-digit" minute="2-digit" />
                                </p>
                            )}
                        </div>

                        {event && (
                            <div className={styles.eventStatus}>
                                <p>
                                    <FormattedMessage id="events.event.athletes.eventStatus" />
                                </p>
                                {event.status !== 'Completed' ? (
                                    <span className={styles.upcoming}>
                                        <FormattedMessage id="events.event.athletes.upcomingLive" />
                                    </span>
                                ) : (
                                    <span className={styles.completed}>
                                        <FormattedMessage id="events.event.athletes.completed" />
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <div className={styles.header}>
                    <Link to={`/events/${+this.props.match.params.id}/summary`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>
                    <h1>
                        <FormattedMessage id="events.event.athletes.title" />
                    </h1>
                </div>
            );
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const eventId = this.props.match.params.id;
        const { auth } = this.context;
        const { event } = this.state;

        const isMenuVisible = auth?.authorities.includes('ROLE_ADMIN');
        const isAthletesMenuVisible =
            auth?.authorities.includes('ROLE_ADMIN') ||
            (auth?.authorities.includes('ROLE_ORGANIZATION') && event?.organizationId === auth.organizationId);

        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="event-dashboard"
                        icon={<Icon component={backSvg} />}
                        onClick={() => this.props.history.push('/events')}
                    >
                        <Link to="/events">
                            <FormattedMessage id="events.event.backToEvents" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="event-menu" title={!collapsed && <FormattedMessage id="events.event.info" />}>
                    <Menu.Item
                        key="event"
                        icon={<Icon component={eventSummarySvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/summary`)}
                    >
                        <Link to={`/events/${eventId}/summary`}>
                            <FormattedMessage id="events.event.summary" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-settings"
                        icon={<Icon component={eventSettingsSvg} />}
                        hidden={!isMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/settings`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/settings`}>
                            <FormattedMessage id="events.event.settings" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="leaderboard"
                        icon={<Icon component={eventLeaderboardSvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/leaderboard`)}
                    >
                        <Link to={`/events/${eventId}/leaderboard`}>
                            <FormattedMessage id="events.event.leaderboard" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-players"
                        icon={<Icon component={positionsSvg} />}
                        hidden={!isAthletesMenuVisible}
                        onClick={() => this.props.history.push(`/events/${eventId}/players`)}
                    >
                        <Link to={`/events/${eventId}/players`}>
                            <FormattedMessage id="events.event.athletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    renderStaffTable = (): React.ReactElement | undefined => {
        const { eventStaff, loading } = this.state;

        const columns: ColumnsType<EventStaff> = [
            {
                title: <FormattedMessage id="events.event.athletes.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                sorter: true,
                width: 300,
                render: (value: string, staff: EventStaff) => (
                    <span className={styles.name}>{stringService.getName(true, value, staff.familyName)}</span>
                ),
            },
            {
                title: <FormattedMessage id="events.event.athletes.role" />,
                dataIndex: 'roles',
                key: 'roles',
                sorter: true,
                width: 300,
                render: (value: string, staff: EventStaff) =>
                    staff.roles?.map((role) => this.props.intl.formatMessage({ id: `roles.${role}` })).join(', '),
            },
            {
                title: <FormattedMessage id="events.event.athletes.email" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, staff: EventStaff) => value,
            },
        ];

        return (
            <>
                <h2>
                    <FormattedMessage id="events.event.athletes.staff" />
                </h2>
                <Table
                    dataSource={eventStaff}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                    loading={loading === 'initializing' || loading === 'loadingStaff'}
                    onChange={this.listStaff}
                    className={styles.table}
                />
            </>
        );
    };

    renderTableHeader = (): React.ReactElement | undefined => {
        const { event, selectedRowKeys } = this.state;
        const { auth } = this.context;

        let participatedOptions = participatedStatus.map((ps) => (
            <Select.Option key={ps} value={ps}>
                <FormattedMessage id={ps} />
            </Select.Option>
        ));

        participatedOptions = [
            <Select.Option key="" value="">
                {<FormattedMessage id="button.all" />}
            </Select.Option>,
            ...participatedOptions!,
        ];
        return (
            <>
                <h2>
                    <FormattedMessage id="events.event.athletes.players" />
                </h2>
                <header className={styles.tableHeader}>
                    <Search
                        placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                        className={styles.search}
                        enterButton={''}
                        allowClear={true}
                        onSearch={(value) => this.search(value)}
                    />
                    <div className={styles.buttons}>
                        {event ? (
                            event?.status !== 'Completed' ? (
                                <Dropdown
                                    className={styles.addUser}
                                    overlay={
                                        <Menu>
                                            <Link to={`/events/${event?.id}/players/new`}>
                                                <Menu.Item key="addNewPlayer" disabled={!event}>
                                                    <FormattedMessage id="events.event.athletes.addNewPlayer" />
                                                </Menu.Item>
                                            </Link>
                                            {rolesService.hasAnyRole(auth, [
                                                'ROLE_ADMIN',
                                                'ROLE_ORGANIZATION_OWNER',
                                            ]) && (
                                                <Menu.Item
                                                    key="addExistingPlayer"
                                                    onClick={() => this.showExistingUserModal(true)}
                                                >
                                                    <FormattedMessage id="events.event.athletes.addExistingPlayer" />
                                                </Menu.Item>
                                            )}
                                            <Menu.Item key="addGroups" onClick={() => this.showGroupsModal(true)}>
                                                <FormattedMessage id="events.event.athletes.addGroups" />
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <Button type="text" className={styles.actions}>
                                        <FormattedMessage id="button.add" />
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            ) : (
                                <Button
                                    className={styles.button}
                                    onClick={() => this.showModalSendEvalEmails(true)}
                                    disabled={selectedRowKeys.length === 0}
                                >
                                    <FormattedMessage id="events.event.athletes.sendEvalEmails" />
                                </Button>
                            )
                        ) : (
                            <></>
                        )}
                        <Button
                            className={styles.button}
                            onClick={() => this.showModalSendInvites(true)}
                            disabled={selectedRowKeys.length === 0}
                        >
                            <FormattedMessage id="events.event.athletes.inviteSelected" />
                        </Button>
                        {event ? (
                            event?.status !== 'Completed' ? (
                                <Link to={`/events/${event?.id}/qr-codes`}>
                                    <Button className={styles.buttonQR} disabled={!event}>
                                        <FormattedMessage id="events.event.athletes.qrCodes" />
                                    </Button>
                                </Link>
                            ) : (
                                <Select
                                    className={styles.buttonQR}
                                    size="large"
                                    placeholder={<FormattedMessage id="button.all" />}
                                    onChange={this.filterByParticipatedStatus}
                                >
                                    {participatedOptions}
                                </Select>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </header>
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { playersPage, loading, selectedRowKeys, event, groupsModalVisible, existingUserModalVisible } =
            this.state;

        const players = playersPage && playersPage.items ? playersPage.items : [];

        let columns: ColumnsType<EventPlayer> = [
            {
                title: <FormattedMessage id="events.event.athletes.firstName" />,
                dataIndex: 'givenName',
                key: 'givenName',
                width: 160,
                sorter: true,
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="events.event.athletes.lastName" />,
                dataIndex: 'familyName',
                key: 'familyName',
                width: 160,
                sorter: true,
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="events.event.athletes.username" />,
                dataIndex: 'userName',
                key: 'userName',
                width: 100,
                sorter: true,
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="events.event.athletes.division" />,
                dataIndex: 'division',
                key: 'division',
                width: 130,
                sorter: true,
                render: (division: any, player: EventPlayer) => this.getDivision(player),
            },
            {
                title: <FormattedMessage id="events.event.athletes.team" />,
                dataIndex: 'team',
                key: 'team',
                render: (team: any, player: EventPlayer) => this.getTeam(player),
            },
            {
                title: <FormattedMessage id="events.event.athletes.affiliate" />,
                dataIndex: 'affiliate',
                key: 'affiliate',
                sorter: true,
                render: (value: string, player: EventPlayer) => this.getAffiliate(player),
            },
            {
                title: <FormattedMessage id="events.event.athletes.participatedStatus" />,
                width: 160,
                key: 'participated',
                dataIndex: 'participated',
                render: (participated: boolean) =>
                    participated === true && (
                        <>
                            <FormattedMessage id="events.event.athletes.participated" /> <CheckCircleFilled />
                        </>
                    ),
            },
            {
                title: <FormattedMessage id="events.event.athletes.evalEmail" />,
                width: 160,
                key: 'emailSent',
                dataIndex: 'emailSent',
                render: (emailSent: boolean) =>
                    emailSent === true && (
                        <>
                            <FormattedMessage id="events.event.athletes.evalSent" /> <CheckCircleFilled />
                        </>
                    ),
            },
            {
                title: <FormattedMessage id="events.event.athletes.inviteStatus" />,
                width: 160,
                key: 'userStatus',
                dataIndex: 'userStatus',
                sorter: true,
                render: (userStatus: UserStatusType) =>
                    userStatus !== 'None' && (
                        <>
                            <FormattedMessage id="events.event.athletes.invited" /> <CheckCircleFilled />
                        </>
                    ),
            },
        ];

        columns =
            event?.status !== 'Completed'
                ? columns.filter((col) => col.key !== 'participated' && col.key !== 'emailSent')
                : columns;

        const rowSelection: TableRowSelection<EventPlayer> = {
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: this.select,
        };
        return (
            <>
                {this.renderStaffTable()}
                {this.renderTableHeader()}
                <Table
                    dataSource={players}
                    columns={columns}
                    pagination={tableService.createPagination(playersPage, { pageSize: this.pageSize })}
                    rowKey="id"
                    onChange={this.list}
                    loading={loading === 'initializing' || loading === 'loadingPlayers'}
                    rowSelection={rowSelection}
                    className={styles.table}
                />
                {this.renderSendInvitesModal()}
                {this.renderSendEvalEmailsModal()}
                {groupsModalVisible && event && (
                    <AddGroupsModal
                        event={event}
                        onUpdate={this.updatePlayers}
                        onCancel={() => this.showGroupsModal(false)}
                    />
                )}
                {existingUserModalVisible && event && (
                    <AddExistingPlayerModal
                        event={event}
                        onUpdate={this.updatePlayers}
                        onCancel={() => this.showExistingUserModal(false)}
                        playersPage={playersPage}
                    />
                )}
            </>
        );
    };

    renderSendInvitesModal = (): React.ReactElement | undefined => {
        const { modalSendInvitesVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="events.event.athletes.modalSendInvites.title" />}
                visible={modalSendInvitesVisible}
                okText={<FormattedMessage id="events.event.athletes.modalSendInvites.button" tagName="span" />}
                onOk={this.sendInvites}
                okButtonProps={{ loading: loading === 'sendingInvites' }}
                onCancel={() => this.showModalSendInvites(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="events.event.athletes.modalSendInvites.description" />
                </p>
            </Modal>
        );
    };

    renderSendEvalEmailsModal = (): React.ReactElement | undefined => {
        const { modalSendEvalEmailsVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="events.event.athletes.modalSendEval.title" />}
                visible={modalSendEvalEmailsVisible}
                okText={<FormattedMessage id="events.event.athletes.modalSendEval.button" tagName="span" />}
                onOk={() => this.sendEvalEmails(+this.props.match.params.id)}
                okButtonProps={{ loading: loading === 'sendingEmails' }}
                onCancel={() => this.showModalSendEvalEmails(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="events.event.athletes.modalSendEval.description" />
                </p>
            </Modal>
        );
    };

    render() {
        const { event } = this.state;
        const fullName =
            event &&
            `${this.props.intl.formatMessage({
                id: 'event.athletes.meta.title',
            })} - ${event?.name} - ${this.props.intl.formatMessage({
                id: 'events.event.event.meta.title',
            })}`;
        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="event-players"
                    menu={this.renderMenu}
                    content={this.renderContent}
                    header={this.renderHeader}
                />
            </>
        );
    }
}
export default injectIntl(EventPlayersPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    playersPage?: Page<EventPlayer>;
    modalSendInvitesVisible?: boolean;
    modalSendEvalEmailsVisible?: boolean;
    loading?:
        | 'sendingEmails'
        | 'sendingInvites'
        | 'initializing'
        | 'searching'
        | 'filtering'
        | 'loadingPlayers'
        | 'loadingStaff';
    selectedRowKeys: Key[];
    searchText?: string;
    participated?: boolean;
    divisions: Division[];
    eventStaff: EventStaff[];
    sortField?: string;
    sortOrder?: string;
    groupsModalVisible?: boolean;
    existingUserModalVisible?: boolean;
}
