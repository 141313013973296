import * as React from 'react';
import styles from './LayoutComponent.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Layout, Spin } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import NavigationComponent from '../NavigationComponent/NavigationComponent';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { PageType } from '../../model/Types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// eslint-disable-next-line react-hooks/exhaustive-deps
const useMountEffect = (fun: any) => React.useEffect(fun, []);

const LayoutComponent: React.FC<Props> = (props: Props) => {
    const [collapsed, setCollapsed] = React.useState(false);
    let asideClass = styles.navigation;

    useMountEffect(() => {
        if (localStorage.getItem('collapsed') !== null && localStorage.getItem('collapsed') === 'true') {
            setCollapsed(true);
        }
    });

    // calculate screen size
    const screens = useBreakpoint();
    const desktop: boolean = Object.entries(screens)
        .filter((screen) => !!screen[1])
        .some((s) => ['md', 'lg', 'xl', 'xxl'].includes(s[0]));

    /**
     * Collapses or expands the sidebar menu.
     * @param collapsed if the sidebar shuld be collapsed
     */
    const collapse = (collapsed: boolean) => {
        desktop ? localStorage.setItem('collapsed', collapsed.toString()) : localStorage.removeItem('collapsed');
        setCollapsed(collapsed);
    };

    /**
     * Returns the header (only mobile).
     * @returns the header
     */
    const renderHeader = (): React.ReactNode | undefined => {
        const { header, logo, menu, page } = props;

        return (
            <Header className={styles.header}>
                <div className={styles.topMobile}>
                    <div className={styles.left}>
                        {logo ? (
                            logo(false)
                        ) : (
                            <>
                                <Link to="">
                                    <div className={styles.logo} />
                                </Link>
                                <Button type="link" className={styles.toggle} onClick={() => collapse(true)} />
                            </>
                        )}
                    </div>

                    {header && <div className={styles.buttons}>{header(false)}</div>}
                </div>
                <Drawer
                    title={
                        <div className={styles.top}>
                            <div className={styles.logo} />
                            <CloseOutlined onClick={() => collapse(false)} className={styles.toggle} />
                        </div>
                    }
                    placement="left"
                    closable={false}
                    onClose={() => collapse(false)}
                    visible={collapsed}
                    className={styles.drawer}
                >
                    <NavigationComponent
                        renderMenu={menu}
                        page={page}
                        collapsed={false}
                        userId={props.userId}
                        sportId={props.sportId}
                        familyId={props.familyId}
                        organizationIds={props.organizationIds}
                    />
                </Drawer>
            </Header>
        );
    };

    /**
     * Retutns the navigation sidebar (only desktop).
     * @returns the navigation bar
     */
    const renderNavigation = (): React.ReactNode | undefined => {
        const { menu, page } = props;

        collapsed ? (asideClass = styles.navigationClosed) : (asideClass = styles.navigation);

        return (
            <Sider
                width="256"
                collapsedWidth="102"
                collapsible
                breakpoint="md"
                trigger={null}
                collapsed={collapsed}
                className={asideClass}
            >
                <div className={styles.top}>
                    <Link to="">
                        <img className={styles.logo} src={process.env.PUBLIC_URL + '/img/top100-logo.png'} alt="" />
                    </Link>
                    <button hidden={collapsed} onClick={() => collapse(true)} className={styles.toggleClose}>
                        <FormattedMessage id="button.close" />
                    </button>
                    <button hidden={!collapsed} onClick={() => collapse(false)} className={styles.toggleOpen}>
                        <FormattedMessage id="button.open" />
                    </button>
                </div>
                <NavigationComponent
                    renderMenu={menu}
                    page={page}
                    collapsed={collapsed}
                    userId={props.userId}
                    sportId={props.sportId}
                    familyId={props.familyId}
                    organizationIds={props.organizationIds}
                />
            </Sider>
        );
    };

    /**
     * Returns the content page wrapped in the layout template.
     * @param desktop - if the screen size is desktop
     * @returns the content page wrapped in the layout template
     */
    const renderContent = (desktop: boolean): React.ReactNode | undefined => {
        const { header, content, style } = props;

        return (
            <Layout hidden={props.loading} className={styles.main}>
                {desktop && header && <div className={styles.subheader}>{header(true)}</div>}
                <Content className={styles.content} style={style}>
                    {content(desktop)}
                </Content>
                <Footer className={styles.footer}></Footer>
            </Layout>
        );
    };

    /**
     * Returns a spinner when the page is initializing.
     * @returns a spinner.
     */
    const renderLoader = (): React.ReactNode | undefined => {
        return (
            <Layout className={styles.main}>
                <Spin size="large" className={styles.spinner}></Spin>
            </Layout>
        );
    };

    return (
        <Layout className={styles.layout} hasSider={desktop}>
            {desktop ? renderNavigation() : renderHeader()}
            {props.loading && renderLoader()}
            {renderContent(desktop)}
        </Layout>
    );
};
export default LayoutComponent;

interface Props {
    userId?: string;
    sportId?: number;
    familyId?: number;
    organizationIds?: string[];
    content: (desktop: boolean) => React.ReactNode | undefined;
    menu?: (collapsed: boolean) => React.ReactNode | undefined;
    header?: (desktop: boolean) => React.ReactNode | undefined;
    logo?: (desktop: boolean) => React.ReactNode | undefined;
    page?: PageType;
    style?: React.CSSProperties;
    loading?: boolean;
}
