import Icon from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CustomContext from '../../../../context/CustomContext';
import { CustomAuth } from '../../../../model/Entities';
import { ReactComponent as dashboardSvg } from '../../../../resources/images/ico-dashboard.svg';
import { ReactComponent as pacerSvg } from '../../../../resources/images/ico-pacer.svg';
import { ReactComponent as playerCodeSvg } from '../../../../resources/images/ico-playerCode.svg';
import { ReactComponent as playerDataSvg } from '../../../../resources/images/ico-playerData.svg';
import { ReactComponent as playerEditSvg } from '../../../../resources/images/ico-playerEdit.svg';
import { ReactComponent as playerPhotosSvg } from '../../../../resources/images/ico-playerPhotos.svg';
import { ReactComponent as playerProfileSvg } from '../../../../resources/images/ico-playerProfile.svg';
import { ReactComponent as travelSvg } from '../../../../resources/images/ico-travel.svg';
import ga from '../../../../services/GoogleAnalytics';
import styles from './PlayerMenuComponent.module.scss';

class PlayerMenuComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    callAnalyticsPacerEvent = () => {
        ga.createDefaultEvent('pacer', 'pacer - redirect to PACER from menu');
    };

    callAnalyticsTravelEvent = () => {
        ga.createDefaultEvent('travel', 'travel - redirect to travel page from menu');
    };

    createParams = (): string => {
        const { sportId, familyId } = this.props;

        return `sportId=${sportId}&familyId=${familyId}`;
    };

    /*** COMPONENTS ***/

    renderTitle = (): React.ReactNode | undefined => {
        const { userId, collapsed } = this.props;

        if (!collapsed && this.context.auth?.id === userId) {
            return <FormattedMessage id="navigation.player.dashboard" />;
        } else if (!collapsed) {
            return <FormattedMessage id="navigation.menu.player" />;
        }
    };

    renderMyProfileMenuItem = (editable: boolean, auth?: CustomAuth): React.ReactNode | undefined => {
        if (!editable && !!auth && !this.context.auth?.authorities.includes('ROLE_PARENT')) {
            return (
                <>
                    <Menu.Item
                        key="dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() => this.props.history.push('')}
                    >
                        <Link to="">
                            <FormattedMessage id="navigation.player.dashboard.myProfile" />
                        </Link>
                    </Menu.Item>
                    <Divider className="divider" />
                </>
            );
        }
    };

    renderPlayerMenu = (): React.ReactNode | undefined => {
        const { auth } = this.context;
        const { userId, editable, divider } = this.props;
        const params = this.createParams();

        if (editable === true || editable === false) {
            return (
                <>
                    {this.renderMyProfileMenuItem(editable, auth)}

                    {(editable || this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')) && (
                        <Menu.Item
                            key="player-profile"
                            icon={<Icon component={playerProfileSvg} />}
                            onClick={() => this.navigate(`/players/${userId}/profile?${params}`)}
                        >
                            <Link to={`/players/${userId}/profile?${params}`}>
                                <FormattedMessage id="navigation.player.dashboard.profile" />
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key="player-data"
                        icon={<Icon component={playerDataSvg} />}
                        onClick={() => this.navigate(`/players/${userId}/data?${params}`)}
                    >
                        <Link to={`/players/${userId}/data?${params}`}>
                            <FormattedMessage id="navigation.player.dashboard.data" />
                        </Link>
                    </Menu.Item>
                    {editable && (
                        <Menu.Item
                            key="player-edit"
                            icon={<Icon component={playerEditSvg} />}
                            onClick={() => this.navigate(`/players/${userId}/edit?${params}`)}
                        >
                            <Link to={`/players/${userId}/edit?${params}`}>
                                <FormattedMessage id="navigation.player.dashboard.edit" />
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key="player-media"
                        icon={<Icon component={playerPhotosSvg} />}
                        onClick={() => this.navigate(`/players/${userId}/media?${params}`)}
                    >
                        <Link to={`/players/${userId}/media?${params}`}>
                            <FormattedMessage id="navigation.player.dashboard.media" />
                        </Link>
                    </Menu.Item>
                    {editable && (
                        <>
                            <Menu.Item
                                key="player-code"
                                icon={<Icon component={playerCodeSvg} />}
                                onClick={() => this.navigate(`/players/${userId}/code?${params}`)}
                            >
                                <Link to={`/players/${userId}/code?${params}`}>
                                    <FormattedMessage id="navigation.player.dashboard.code" />
                                </Link>
                            </Menu.Item>
                            <Menu.Item
                                key="pacer"
                                icon={<Icon component={pacerSvg} />}
                                onClick={this.callAnalyticsPacerEvent}
                            >
                                <a href="https://top100sports.com/PACER" target="_blank" rel="noreferrer">
                                    <FormattedMessage id="navigation.player.dashboard.pacer" />
                                </a>
                            </Menu.Item>
                            <Menu.Item
                                key="travel"
                                icon={<Icon component={travelSvg} />}
                                onClick={this.callAnalyticsTravelEvent}
                            >
                                <a
                                    href="https://www.sarecsportstravel.com/top100sports"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FormattedMessage id="navigation.player.dashboard.travel" />
                                </a>
                            </Menu.Item>
                        </>
                    )}
                    {divider && <Divider className={styles.divider} />}
                </>
            );
        }
    };

    render() {
        const { additionalMenu } = this.props;

        return (
            <Menu.ItemGroup key="admin-player" title={this.renderTitle()}>
                {this.renderPlayerMenu()}
                {additionalMenu}
            </Menu.ItemGroup>
        );
    }
}
export default injectIntl(withRouter(PlayerMenuComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    collapsed: boolean;
    editable?: boolean;
    userId: string;
    sportId: number;
    familyId?: number;
    organizationIds?: string[];
    divider?: boolean;
    additionalMenu?: React.ReactNode;
}
