import React, { useContext, useEffect, useState } from 'react';
import leaderboardApi from '../../apis/LeaderboardApi';
import CustomContext from '../../context/CustomContext';
import { GenderType, LeaderboardType } from '../../model/Types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

/**
 * This component display the Top100 Sports Index score of a player.
 */
const PlayerIndexScoreComponent: React.FC<Props> = (props) => {
    const { sportId, gender, seasonId, leaderboardType, organizationId, userId } = props;
    const context = useContext(CustomContext);
    const { auth } = context;

    /**^* METHODS */

    /*** HOOKS */

    const [indexScore, setIndexScore] = useState<number>();
    const [loading, setLoading] = useState<'loading'>();

    useEffect(() => {
        const init = async () => {
            try {
                if (sportId && gender && seasonId && leaderboardType && userId) {
                    setLoading('loading');
                    const leaderboard = await leaderboardApi.list(
                        2000,
                        1,
                        sportId,
                        'ascend',
                        undefined,
                        undefined,
                        gender,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        seasonId,
                        leaderboardType,
                        organizationId,
                    );
                    const player = leaderboard.items.find((p) => p.athlete.userId === userId);
                    setIndexScore(player?.index);
                } else {
                    setIndexScore(undefined);
                }
            } catch (error) {
            } finally {
                setLoading(undefined);
            }
        };

        init();
    }, [auth, sportId, gender, seasonId, leaderboardType, organizationId, userId]);

    /*** COMPONENTS ***/

    if (loading === 'loading') {
        return <>&nbsp;</>;
    } else if (indexScore !== undefined) {
        return <FormattedNumber value={indexScore * 100} minimumFractionDigits={0} maximumFractionDigits={0} />;
    } else {
        return <FormattedMessage id="--" />;
    }
};
export default PlayerIndexScoreComponent;

interface Props {
    sportId: number | undefined;
    gender: GenderType | undefined;
    seasonId: number | undefined;
    leaderboardType: LeaderboardType | undefined;
    organizationId: string | undefined;
    userId: string | undefined;
}
